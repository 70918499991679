$(document).ready(function () {
	//Wrap navigation to more dropdown link
	$("nav.nav-sec").css("display", "block");
	$("nav.nav-sec ul.nav-list").navwrap();

	//Testimonials Carousel
	//http://caroufredsel.falsecode.ru/docs.html
	$(".testimonial-list-container").carouFredSel({
		items: 1,
		height: 200,
		responsive: true,
		scroll: {
			items: 1,
			easing: "linear",
			duration: 1000,
			pauseOnHover: true,
		},
	});

	//Side Search (Show/Hide)
	$(".quick-search-btn").on("click", function() {
		$(".quick-search-form").css({ right: 0 });
	});

	$(".close-quick-search").on("click", function() {
		$(".quick-search-form").css({ right: "-400px" });
	});

	//Featured Listing Carousel
	$(".featuredListing-wrap .featured_listings").owlCarousel({
		items: 4,
		dots: true,
		nav: false,
		responsiveClass: true,
		responsive:{
			0:{
				items:1,
			},
			560:{
				items:2,
			},
			830:{
				items:3, 
			},
			1050:{
				items:4,
			}
			
		}
	});

});
